@import url("//fonts.googleapis.com/css?family=Economica");
.odometer.odometer-auto-theme,
.odometer.odometer-theme-train-station {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-train-station .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
.odometer.odometer-theme-train-station .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-train-station .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
.odometer.odometer-theme-train-station .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
.odometer.odometer-theme-train-station .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value,
.odometer.odometer-theme-train-station .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme
  .odometer-digit
  .odometer-value.odometer-last-value,
.odometer.odometer-theme-train-station
  .odometer-digit
  .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
.odometer.odometer-theme-train-station.odometer-animating-up
  .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-train-station.odometer-animating-up.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
.odometer.odometer-theme-train-station.odometer-animating-down
  .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner,
.odometer.odometer-theme-train-station.odometer-animating-down.odometer-animating
  .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer.odometer-auto-theme,
.odometer.odometer-theme-train-station {
  font-family: "Economica", sans-serif;
}
.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-theme-train-station .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  -moz-border-radius: 0.1em;
  -webkit-border-radius: 0.1em;
  border-radius: 0.1em;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzExMTExMSIvPjxzdG9wIG9mZnNldD0iMzUlIiBzdG9wLWNvbG9yPSIjMTExMTExIi8+PHN0b3Agb2Zmc2V0PSI1NSUiIHN0b3AtY29sb3I9IiMzMzMzMzMiLz48c3RvcCBvZmZzZXQ9IjU1JSIgc3RvcC1jb2xvcj0iIzExMTExMSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzExMTExMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==");
  background-size: 100%;
  background-image: -webkit-gradient(
    linear,
    50% 0%,
    50% 100%,
    color-stop(0%, #111111),
    color-stop(35%, #111111),
    color-stop(55%, #333333),
    color-stop(55%, #111111),
    color-stop(100%, #111111)
  );
  background-image: -moz-linear-gradient(
    top,
    #111111 0%,
    #111111 35%,
    #333333 55%,
    #111111 55%,
    #111111 100%
  );
  background-image: -webkit-linear-gradient(
    top,
    #111111 0%,
    #111111 35%,
    #333333 55%,
    #111111 55%,
    #111111 100%
  );
  background-image: linear-gradient(
    to bottom,
    #111111 0%,
    #111111 35%,
    #333333 55%,
    #111111 55%,
    #111111 100%
  );
  background-color: #222;
  padding: 0 0.15em;
  color: #fff;
}
.odometer.odometer-auto-theme .odometer-digit + .odometer-digit,
.odometer.odometer-theme-train-station .odometer-digit + .odometer-digit {
  margin-left: 0.1em;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
.odometer.odometer-theme-train-station .odometer-digit .odometer-digit-inner {
  left: 0.15em;
}

.odometer {
  font-size: 20px;
}

.App {
  text-align: center;
  margin-top: 50px;
  /* background-color: #ffd54f; */
}
.logo {
  height: 120px;
}

.App-logo {
  height: 150px;
  /* pointer-events: none; */
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */
/* .icon {
  height: 300px;
} */

.App-header {
  background-color: #282c34;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  /* background-color: #ffd54f; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.banner {
  text-align: center !important;
  background-color: #ffd54f;
  display: flex;
  padding: 20px;
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 20px;
}

.registration-count {
  z-index: 9999;
  text-align: center !important;
  background-color: #fbd54f;
  display: flex;
  padding: 5px;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 15px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  color: black;
}

/*
* countdown css
*/

.countdown {
  align-items: center;
  background-color: #ffd54f;
  display: flex;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.countdown .container {
  color: #333;
  margin: 50px auto;
  text-align: center;
}

.countdown h1 {
  font-weight: 500;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.countdown li {
  display: inline-block;
  font-size: 1.5em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
}

.countdown li span {
  display: block;
  font-size: 4.5rem;
}

.countdown .emoji {
  display: none;
  padding: 1rem;
}

.countdown .emoji span {
  font-size: 4rem;
  padding: 0 0.5rem;
}

@media all and (max-width: 768px) {
  .countdown h1 {
    font-size: calc(1.5rem * var(--smaller));
  }

  .countdown li {
    font-size: calc(1.125rem * var(--smaller));
  }
  .countdown li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}

.user-time{
  z-index: 9999;
  text-align: center !important;
  background-color: #ffd54f;
  display: flex;
  padding: 0px;
  /* margin-bottom: 10px; */
  font-weight: 700;
  font-size: 10px;
  /* top: 60px; */
  left: 0;
  right: 0;
  position: fixed;
  color: black;
}

.clock-radius{
  background-color:#fff !important ;
  font-weight: '100';
  margin-top: -70px;
  border-radius: 70px;
}